import React from "react"
import "../styles/header.scss"
import { Link } from "gatsby"
import { NAME, GITHUB, LINKEDIN } from "../constants"

export default function Header() {
  return (
    <div className="header-container">
      <h1 className="header-left">{NAME}</h1>
      <div className="header-right">
        <ul>
          <Link to="/#my-work">Work</Link>
          <Link to="/#contact">Contact</Link>
          <Link to={GITHUB}>Github</Link>
          <Link to={LINKEDIN}>LinkedIn</Link>
        </ul>
      </div>
    </div>
  )
}
